import { version } from "../package.json";
var WebsailLoader = /** @class */ (function () {
    function WebsailLoader(config) {
        var _this = this;
        this.VERSION = process.env.VERSION || version;
        this.args = [];
        this.constructorArgs = [];
        this.isAdmin = false;
        this.onLoadBundle = function () {
            for (var _i = 0, _a = _this.constructorArgs; _i < _a.length; _i++) {
                var constructorArgs = _a[_i];
                var integration = new window.wsBundleIntegration(constructorArgs);
                _this.isAdmin
                    ? integration.initAdmin()
                    : integration.init.apply(integration, (_this.args.shift() || []));
            }
            _this.init.apply(_this, (_this.args.shift() || []));
        };
        if ((config === null || config === void 0 ? void 0 : config.loader) !== true) {
            if (window.wsBundleIntegration) {
                return new window.wsBundleIntegration(config);
            }
            this.constructorArgs.push(config);
        }
        this.loadScripts(config);
    }
    WebsailLoader.prototype.loadScripts = function (config) {
        if (config === void 0) { config = {}; }
        if (!config.apiBaseUrl) {
            return;
        }
        if (window.websailBundle) {
            return;
        }
        var basePath = config.apiBaseUrl;
        if (basePath.indexOf("?") >= 0) {
            basePath = basePath.slice(0, basePath.indexOf("?"));
        }
        var filePath = new URL("bee-react-form/bundle.min.js", basePath).href;
        filePath += "?v=" + this.VERSION;
        var sNew = document.createElement("script");
        sNew.onload = this.onLoadBundle;
        sNew.src = filePath;
        document.body.append(sNew);
        // loaderScript.insertAdjacentElement("afterend", sNew);
    };
    WebsailLoader.prototype.init = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (args.length === 0) {
            return;
        }
        this.loadScripts(args[0]);
        var websailBundle = window.websailBundle;
        if (websailBundle) {
            websailBundle.init.apply(websailBundle, (this.args.shift() || args));
            return;
        }
        this.args.push(args);
    };
    WebsailLoader.prototype.initAdmin = function () {
        this.isAdmin = true;
    };
    return WebsailLoader;
}());
window.wsintegration = WebsailLoader;
window.websail = new WebsailLoader({ loader: true });
export default {};
